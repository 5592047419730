@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';
@import '../../../assets/scss/mixins/custom-style.scss';

.ninthScreenContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: size-rem(180px);
  background-color: $clr-light-grey;
}

.ninthScreenWrapper {
  overflow: visible;
  @include flex-row;
  @include vp--740 {
    @include flex-column;
  }
}

.ninthScreenBox {
  width: 50%;
  @include vp--740 {
    width: 100%;
    height: 100%;
  }
}

.ninthScreenImgInner {
  position: relative;
  height: size-rem(336px);

  @include vp--740 {
    height: size-rem(158px, true);
    width: 100%;
  }
}

.ninthScreenLogo {
  position: relative;
  top: size-rem(170px);
  @include vp--740 {
    top: size-rem(46px, true);
    margin-bottom: size-rem(17px, true);
    margin-left: 5%;
    margin-top: size-rem(50px, true);
  }
}

.ninthScreenTitle {
  position: relative;
  top: size-rem(388px);
  left: size-rem(80px);
  z-index: 1;
  @include vp--740 {
    top: size-rem(60px, true);
    left: 5%;
  }
}
.ninthScreenTitle p {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(30px);
  line-height: 120%;
  letter-spacing: -0.02em;
  color: $clr-dark-grey;
  margin: size-rem(5px);
  @include vp--740 {
    font-size: size-rem(20px, true);
  }
}

.ninthScreenDescription {
  width: size-rem(664px);
  margin-top: size-rem(160px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 400;
  font-size: size-rem(18px);
  line-height: 140%;
  letter-spacing: -0.02em;
  color: $clr-dark-grey;
  position: relative;
  top: size-rem(30px);
  @include vp--740 {
    width: size-rem(368px, true);
    font-size: size-rem(18px, true);
    margin-left: 5%;
    margin-top: size-rem(64px, true);
  }
}

.ninthScreenBoxLink {
  display: flex;
  align-items: center;
  margin-top: size-rem(44px);
  @include p-18;
  font-family: $primary-font-family;
  color: $clr-dark-black;
  column-gap: size-rem(14px);

  &:hover {
    text-decoration: underline;
    text-underline-offset: size-rem(2px);
  }

  @include vp--740 {
    font-size: size-rem(18px, true);
    margin-left: 5%;
    margin-top: size-rem(44px, true);
  }
}

.ninthScreenImagesBox {
  @include flex-row_sb;
  justify-content: flex-start;
  overflow: hidden;
  position: relative;
  @include vp--740 {
    width: size-rem(368px, true);
    height: size-rem(173px, true);
    margin-top: size-rem(13px, true);
    margin-left: 5%;
  }
}

.svgClass {
  width: size-rem(252px);
  height: size-rem(90px);
  @include vp--740 {
    width: size-rem(268px, true);
    height: size-rem(80px, true);
  }
}

.ninthScreenGallery {
  position: relative;
  margin-top: 96px;
  padding-left: 5% !important;
  padding-right: 5% !important;
}

.ninthScreenImg {
  margin-right: size-rem(20px);
  &:nth-last-child(1) {
    margin-right: 0;
  }
}

.ninthScreenImgBox {
  width: size-rem(700px);
  height: size-rem(336px);
  position: relative;
  @include vp--740 {
    width: size-rem(368px, true);
    height: size-rem(173px, true);
    margin-top: size-rem(30px, true);
    margin-bottom: size-rem(10px, true);
  }
}

.ninthScreenImg.__three {
  width: size-rem(700px);
}

.ninthScreenImg.__one {
  width: size-rem(260px);
  height: size-rem(336px);
  @include vp--740 {
    width: size-rem(134px, true);
    height: size-rem(173px, true);
  }
}

.ninthScreenImg.__two {
  width: size-rem(425px);
  height: size-rem(336px);
  @include vp--740 {
    width: size-rem(223px, true);
    height: size-rem(173px, true);
  }
}

.ninthScreenCardTitle {
  margin-bottom: size-rem(16px);
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 350;
  font-size: size-rem(18px);
  line-height: 140%;
  color: $clr-dark-grey;
  display: inline-block;

  @include vp--740 {
    font-size: size-rem(18px, true);
    margin-bottom: size-rem(12px, true);
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.ninthScreenTitleForst {
  position: relative;
  z-index: 1;
  font-size: size-rem(25px);
  color: $clr-dark-grey;
  @include vp--740 {
    top: size-rem(30px, true);
    left: 5%;
    font-size: size-rem(18px, true);
  }
}

.ninthScreenTitleContainer {
  width: 88%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  top: size-rem(38px);
  @include vp--740 {
    left: 5%;
  }
}

.ninthScreenTitleRepublic {
  width: size-rem(260px);
  font-size: size-rem(25px);
  color: $clr-dark-grey;
  @include vp--740 {
    width: size-rem(134px, true);
    font-size: size-rem(18px, true);
  }
}

.ninthScreenTitlePortland {
  width: size-rem(425px);
  font-size: size-rem(25px);
  color: $clr-dark-grey;
  margin-left: size-rem(24px);
  @include vp--740 {
    width: size-rem(223px, true);
    font-size: size-rem(18px, true);
  }
}

.nav {
  position: absolute;
  height: 90%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: size-rem(40px);
}

.prev {
  position: relative;
  margin-left: size-rem(128px);
  cursor: pointer;
}

.next {
  position: relative;
  margin-right: size-rem(128px);
  cursor: pointer;
}

.arrow {
  width: size-rem(160px) !important;
  height: size-rem(160px) !important;
}

.mobCards {
  padding: 0 5% !important;
  position: relative;
  top: size-rem(30px, true);
}

.mobCard {
  margin-bottom: size-rem(20px, true);

  &.__one {
    height: size-rem(200px, true);
  }
  &.__two {
    margin-right: size-rem(10px, true);
  }
  &.__four {
    height: size-rem(200px, true);
  }

  @include vp--740 {
    & img {
      height: size-rem(158px, true) !important;
      object-fit: cover;
    }
  }
}

.middleImages {
  display: flex;
  justify-content: space-between;
  height: fit-content;
}
